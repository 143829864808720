/**
 * ⚠️ WARNING ⚠️
 * This file is automatically generated do not edit it manually
 */

declare type ReleaseNoteData = {
  type: "bugfix" | "release" | "improvement";
  [key: string]: string;
};

export declare type ReleaseNote = {
  id: string;
  language: string;
  html: string;
  data: ReleaseNoteData;
};

export default [
  {
    id: "en-2024_10_31-release_notes-en",
    language: "en",
    html: `
<h2>Introducing: Telia at Work</h2>
<p>Telia at Work provides quick access to essential details about your business mobile subscription, without needing a MyBusiness account. Check your:</p>
<ul>
  <li>
    <p>Subscription type</p>
  </li>
  <li>
    <p>Remaining data and renewal date</p>
  </li>
  <li>
    <p>Data usage</p>
  </li>
  <li>
    <p>PUK code</p>
  </li>
</ul>
<p>
  To get started, the company Superuser or Main Administrator activates Telia at Work via Company Settings in MyBusiness. Once active, any business mobile subscriber in your organisation can log in to Telia at Work using their phone number. Accessible on mobile, tablet, and desktop
  For more information, please visit telia.se/foretag/mer-fran-telia/telia-at-work
</p>
<p>Telia at Work keeps you connected anytime, anywhere.</p>
`,
    data: { date: "31 Oct 2024", type: "release" },
  },
  {
    id: "sv-2024_10_31-release_notes-sv",
    language: "sv",
    html: `
<h2>Lansering: Telia at Work</h2>
<p>Telia at Work ger snabb åtkomst till grundläggande information om ditt mobila jobbabonnemang, utan att behöva ett MyBusiness-konto. Här kan du enkelt kolla:</p>
<ul>
  <li>
    <p>Abonnemangstyp</p>
  </li>
  <li>
    <p>Återstående data och förnyelsedatum</p>
  </li>
  <li>
    <p>Dataförbrukning</p>
  </li>
  <li>
    <p>PUK-kod</p>
  </li>
</ul>
<p>För att komma igång behöver organisationens Superuser eller Huvudadministratör aktivera Telia at Work via Företagsinställningar i MyBusiness. När tjänsten är aktiverad kan alla i organisationen logga in på Telia at Work med sitt telefonnummer. Tjänsten är tillgänglig på mobil, surfplatta och dator. Läs mer om tjänsten på telia.se/foretag/mer-fran-telia/telia-at-work</p>
<p>Telia at Work håller dig uppkopplad när som helst, var som helst.</p>
`,
    data: { date: "31 okt. 2024", type: "release" },
  },
  {
    id: "en-2024_09_18-release_notes-en",
    language: "en",
    html: `
<h2>Enhanced Search in MyBusiness</h2>
<p>Introducing several improvements to the search functionality in MyBusiness, designed to enhance both usability and efficiency.</p>
<ul>
  <li>
    <p>Search Bar Relocation: The search bar has been moved to the top menu for easier access, making it quicker for you to find what you’re looking for.</p>
  </li>
  <li>
    <p>Expanded Search Capabilities: You can now search for support cases, in addition to existing search options.</p>
  </li>
  <li>
    <p>Redesigned Search Page: The search results page has received a design update, offering a more intuitive and user-friendly interface to help you navigate and find results more efficiently.</p>
  </li>
</ul>
<p>These enhancements are aimed at improving usability and saving you time as you manage your business needs within MyBusiness.</p>
`,
    data: { date: "18 Sept 2024", type: "improvement" },
  },
  {
    id: "sv-2024_09_18-release_notes-sv",
    language: "sv",
    html: `
<h2>Förbättrad sökfunktion i MyBusiness</h2>
<p>Förbättringar av sökfunktionen i MyBusiness, utformade för att förbättra både användarvänlighet och effektivitet.</p>
<ul>
  <li>
    <p>Ny placering: Sökfältet har flyttats till toppmenyn för enklare åtkomst, så att du snabbare hittar det du letar efter.</p>
  </li>
  <li>
    <p>Utökade sökmöjligheter: Du kan nu söka efter supportärenden, utöver de befintliga sökalternativen.</p>
  </li>
  <li>
    <p>Nytt utseende på söksidan: Sidan för sökresultat har fått en designuppdatering, med ett mer intuitivt och användarvänligt gränssnitt för att hjälpa dig att navigera och hitta resultat mer effektivt.</p>
  </li>
</ul>
<p>Dessa förbättringar syftar till att förbättra din upplevelsen och spara tid när du hanterar dina affärsbehov i MyBusiness.</p>
`,
    data: { date: "18 sep. 2024", type: "improvement" },
  },
  {
    id: "en-2024_09_12-release_notes-en",
    language: "en",
    html: `
<h2>Updates and improvements on the support page</h2>
<p>We’ve made several enhancements to make managing your support cases easier:</p>
<ul>
  <li>
    <p>Combine filters: You can now combine multiple filters to get more relevant results in the table.</p>
  </li>
  <li>
    <p>Saved filter settings: Your filter settings are saved when you view case details and return to the case list.</p>
  </li>
  <li>
    <p>Number of cases: You can now quickly see the total number of cases matching your search.</p>
  </li>
  <li>
    <p>Saved column settings: Your selected column settings will be saved for the next time you visit the support page.</p>
  </li>
</ul>
`,
    data: { date: "12 Sept 2024", type: "improvement" },
  },
  {
    id: "sv-2024_09_12-release_notes-sv",
    language: "sv",
    html: `
<h2>Uppdateringar och förbättringar på supportsidan</h2>
<p>Vi har gjort flera förbättringar för att underlätta hanteringen av dina supportärenden:</p>
<ul>
  <li>
    <p>Kombinera filter: Nu kan du kombinera flera filter för att få mer relevanta resultat i tabellen.</p>
  </li>
  <li>
    <p>Sparade filterinställningar: Dina filterinställningar sparas när du går in på detaljer och återgår till ärendelistan.</p>
  </li>
  <li>
    <p>Antal ärenden: Du kan nu snabbt se antalet ärenden som matchar din sökning.</p>
  </li>
  <li>
    <p>Sparade kolumninställningar: Dina valda kolumninställningar sparas till nästa gång du besöker supportsidan.</p>
  </li>
</ul>
`,
    data: { date: "12 sep. 2024", type: "improvement" },
  },
  {
    id: "en-2024_06_24-release_notes-en",
    language: "en",
    html: `
<h2>Edit User in MyBusiness: More Intuitive Than Ever!</h2>
<p>Managing users, permissions, and access in MyBusiness is now smoother and more intuitive than ever. Here are the key improvements:</p>
<ul>
  <li>
    <p>Modern and Unified Design: New design aligns perfectly with the MyBusiness look and feel, giving you a seamless and visually appealing experience.</p>
  </li>
  <li>
    <p>Enhanced User-Friendliness: We’ve streamlined the navigation to save you clicks and time. Finding what you need has never been this easy!</p>
  </li>
  <li>
    <p>Improved Accessibility: We’re all about inclusivity. Our latest enhancements ensure everyone can manage their access and permissions effortlessly.</p>
  </li>
</ul>
<p>These upgrades are designed to boost your productivity, and provide a user-friendly experience that keeps getting better. Explore the new, improved MyBusiness User Management.</p>
`,
    data: { date: "24 Jun 2024", type: "improvement" },
  },
  {
    id: "sv-2024_06_24-release_notes-sv",
    language: "sv",
    html: `
<h2>Redigera användare i MyBusiness: Mer intuitivt än någonsin!</h2>
<p>Att hantera användare, behörigheter och åtkomst i MyBusiness är nu smidigare och mer intuitivt än någonsin. Här är de viktigaste förbättringarna:</p>
<ul>
  <li>
    <p>Modern och enhetlig design: Ny design i enlighet med MyBusiness utseende och känsla, för att ge dig en sömlös och visuellt tilltalande upplevelse.</p>
  </li>
  <li>
    <p>Förbättrad användarvänlighet: Vi har förenklat navigeringen för att spara dig klick och tid. Att hitta det du behöver har aldrig varit så enkelt!</p>
  </li>
  <li>
    <p>Förbättrad tillgänglighet: Vi värnar om inkludering. Våra senaste förbättringar säkerställer att alla enkelt kan hantera sin åtkomst och sina behörigheter.</p>
  </li>
</ul>
<p>Dessa uppgraderingar är utformade för att öka din produktivitet och ge en användarvänlig upplevelse som ständigt förbättras. Upptäck den nya, förbättrade användarhanteringen i MyBusiness.</p>
`,
    data: { date: "24 juni 2024", type: "improvement" },
  },
  {
    id: "en-2024_05_15-release_notes-en",
    language: "en",
    html: `
<h2>Introducing: MyBusiness category for portal incidents</h2>
<p>We’re excited to announce a new enhancement in our support case creation process. Starting now, you’ll find a dedicated MyBusiness category when you encounter issues specific to the MyBusiness portal.</p>
<ul>
  <li>Go more digital: By adding specific categories to your incidents, you don´t need to call or email customer service.</li>
  <li>Save time: Cases created under the correct category will be routed more efficiently, ensuring faster resolution.</li>
</ul>
<p>Thank you for your continued trust in our services! If you have any questions or need further assistance, feel free to reach out.</p>
`,
    data: { date: "15 May 2024", type: "improvement" },
  },
  {
    id: "sv-2024_05_15-release_notes-sv",
    language: "sv",
    html: `
<h2>Problem med portalen? Använd nya MyBusiness-kategorin!</h2>
<p>Vi har precis lagt till en ny kategori i ärendetjänsten. Från och med nu väljer du “MyBusiness” när du vill rapportera problem eller ge synpunkter på MyBusiness-portalen.</p>
<ul>
  <li>Mera digitalt: När vi har specifika kategorier i ärendetjänsten behöver du inte ringa eller mejla kundtjänst, utan rapporterar allt digitalt!</li>
  <li>Spara tid: Ärenden som skapas i MyBusiness under rätt kategori kommer att hanteras mer effektivt av kundservice, vilket spar tid och säkerställer snabba lösningar.</li>
</ul>
<p>Tack för att du använder våra tjänster. Har du frågor eller behöver hjälp – hör av dig!</p>
`,
    data: { date: "15 maj 2024", type: "improvement" },
  },
  {
    id: "en-2024_04_23-release_notes-en",
    language: "en",
    html: `
<h2>Report Pages in New Outfit 👘</h2>
<p>We’ve made several improvements to our report pages to make them more user-friendly and efficient.</p>
<ul>
  <li>Accessible design: By aligning with MyBusiness design patterns, the report pages are more accessible, seamless, and faster than before. The organization you select remains consistent as you navigate report pages, and we’ve improved table readability.</li>
  <li>New Features: Explore the new date picker for retrieving reports at the day level. We’ve also added a “submitted by” column so you have information about the case creators. Also, each website tab now has a unique URL, simplifying navigation and sharing.</li>
</ul>
<p>We truly hope that these enhancements will streamline your work. Until next time!</p>
`,
    data: { date: "23 Apr 2024", type: "improvement" },
  },
  {
    id: "sv-2024_04_23-release_notes-sv",
    language: "sv",
    html: `
<h2>Rapportsidorna i ny kostym 👘</h2>
<p>Vi har genomfört flera förbättringar på våra rapportsidor för att göra användarupplevelsen smidigare och effektivare.</p>
<ul>
  <li>Tillgänglig design: Genom att anpassa oss till MyBusiness designmönster har vi skapat ett mer tillgängligt och sömlöst verktyg. Detta har även ökat hastigheten i tjänsten. Den valda organisationen följer nu också med när du navigerar till rapportsidorna, och vi har förbättrat tabellens läsbart.</li>
  <li>Nya funktioner: Du kommer märka att den nya kalenderväljaren ger dig möjlighet att hämta rapporter på dagdetaljnivå! Vi har också lagt till en ny kolumn med information om personen som skapade ärendet. Dessutom! Varje flik på webbplatsen har nu en unik URL, vilket gör det enklare att navigera och dela specifika sidor.</li>
</ul>
<p>Vi hoppas och tror att dessa förbättringar kommer att underlätta ditt arbete. På återseende!</p>
`,
    data: { date: "23 apr. 2024", type: "improvement" },
  },
  {
    id: "en-2024_04_10-release_notes-en",
    language: "en",
    html: `
<h2>Usernames in support cases 💬</h2>
<p>We've made improvements to the support page to increase transparency and efficiency in case management.</p>
<ul>
  <li>Now you can see which colleague has responded in specific support cases! The person is presented with their username.</li>
</ul>
<p>The change is visible in responses from March 19th and onwards. These improvements are part of our ongoing efforts to achieve faster case resolution for our customers.</p>
`,
    data: { date: "10 Apr 2024", type: "improvement" },
  },
  {
    id: "sv-2024_04_10-release_notes-sv",
    language: "sv",
    html: `
<h2>Användarnamn i supportärenden 💬</h2>
<p>Vi har gjort förbättringar på supportsidan för att öka transparensen och effektiviteten i ärendehanteringen.</p>
<ul>
  <li>Nu kan du se vilken kollega som svarat i specifika supportärenden! Personen presenteras med sitt användarnamn.</li>
</ul>
<p>Förändringen syns i svar från 19 mars och senare. Dessa förbättringar är en del av vårt arbete för att öka insynen, vilket vi vet leder till snabbare ärendehantering för våra kunder.</p>
`,
    data: { date: "10 apr. 2024", type: "improvement" },
  },
  {
    id: "en-2024_04_03-release_notes-en",
    language: "en",
    html: `
<h2>Manage mobile subscriptions with ease 📱✨</h2>
<p>We believe handling mobile subscriptions should be straightforward and efficient. That's why we've now gone live with improvements on the management page to better meet our customers' needs. You might notice a change in:</p>
<ul>
  <li>Stability, with bug fixes and quicker performance</li>
  <li>Design, now more unified and user-friendly</li>
  <li>Functionality, including improvements in settings and options.</li>
</ul>
<p>For a limited time, you can even revert to the old management page.</p>
`,
    data: { date: "3 Apr 2024", type: "improvement" },
  },
  {
    id: "sv-2024_04_03-release_notes-sv",
    language: "sv",
    html: `
<h2>Enklare hantering av mobilabonnemang 📱✨</h2>
<p>Att hantera företags mobilabonnemang ska vara enkelt och effektivt. Därför går vi nu live med förbättringar på hanteringssidan för att möta våra kunders behov. Du kommer kanske att märka en förändring i:</p>
<ul>
  <li>Stabiliteten med buggfixar och snabbare prestanda</li>
  <li>Designen, som nu är mer enhetlig och användarvänlig</li>
  <li>Funktionaliteten, där vi gjort förbättringar i inställningar och tillval.</li>
</ul>
<p>Under en begränsad tid kommer du fortfarande kunna besöka den äldre hanteringssidan.</p>
`,
    data: { date: "3 apr. 2024", type: "improvement" },
  },
  {
    id: "en-2024_03_20-release_notes-en",
    language: "en",
    html: `
<h2>Spring cleaning in the Invitation flow 🌸</h2>
<p>Got a new teammate? Exciting! Get ready to experience a much smoother way of inviting users to MyBusiness with all the right permissions and access. We have, among other things:</p>
<ul>
  <li>Redesigned the flow to make it easier for you to tailor the new user's permissions.</li>
  <li>Synced the design with the rest of MyBusiness so you won't get lost.</li>
  <li>Made accessibility adjustments for better inclusion.</li>
  <li>Behind the scenes: Migrated the pages to a framework that allows us to be more flexible in our development. For instance, when we receive feedback from our customers and want to quickly improve the service.</li>
</ul>
`,
    data: { date: "20 Mar 2024", type: "improvement" },
  },
  {
    id: "sv-2024_03_20-release_notes-sv",
    language: "sv",
    html: `
<h2>Vårstädning i inbjudningsflödet 🌸</h2>
<p>Har du en ny kollega i teamet? Vad kul! Du kommer att märka att det nu är enklare att bjuda in personer med rätt behörighet och åtkomst till MyBusiness. Vi har bland annat</p>
<ul>
  <li>Designat om flödet för att du enklare ska kunna skräddarsy den nya användarens behörighet.</li>
  <li>Synkat designen med resten av MyBusiness så att du inte ska tappa bort dig.</li>
  <li>Tillgänglighetsanpassat för bättre inkludering.</li>
  <li>Bakom kulisserna: Migrerat sidorna till ett ramverk som gör att vi kan vara mer flexibla när vi vidareutvecklar. Som när vi får feedback från våra kunder och snabbt vill förbättra tjänsten till exempel.</li>
</ul>
`,
    data: { date: "20 mars 2024", type: "improvement" },
  },
  {
    id: "en-2024_02_09-release_notes-en",
    language: "en",
    html: `
<h2>Support Page Enhancements</h2>
<p>We are continuously working to improve MyBusiness. Our latest release introduce a series of enhancements to Support Page aimed at refining your workflow and enhancing the user experience. Here's an overview of the latest updates:</p>
<ul>
  <li>Persistent Filters: Filter settings on the case list page are now saved. If you apply filters, inspect a specific case, and then return to the list, your filters will remain in place, facilitating smoother navigation.</li>
  <li>Excel Export Improvements: Data mapping for exporting support cases has been improved. Additionally, we have introduced support for the XLSX format, offering more flexibility in how you manage and analyze your data.</li>
  <li>Minor design adjustments to improve usability and the overall look.</li>
</ul>
`,
    data: { date: "9 Feb 2024", type: "improvement" },
  },
  {
    id: "sv-2024_02_09-release_notes-sv",
    language: "sv",
    html: `
<h2>Förbättringar på Supportsidan</h2>
<p>Vi jobbar kontinuerligt för att förbättra MyBusiness. Vår senaste uppdatering introducerar en rad förbättringar på Supportsidan med målet att förfinad ditt arbetsflöde och förbättra användarupplevelsen. Här är en översikt av de senaste uppdateringarna:</p>
<ul>
  <li>Beständiga Filter: Filterinställningar på sidan med ärendelistan sparas nu. Om du tillämpar filter, granskar ett specifikt ärende och sedan återvänder till listan, kommer dina filter att sparas, vilket underlättar smidigare navigering.</li>
  <li>Förbättringar av Excel-export: Datamappning vid export av supportärenden har förbättrats. Dessutom har vi infört stöd för XLSX-formatet, vilket erbjuder mer flexibilitet i hur du hanterar och analyserar dina data.</li>
  <li>Mindre designjusteringar för förbättrad användbarhet och utseende.</li>
</ul>
`,
    data: { date: "9 feb. 2024", type: "improvement" },
  },
  {
    id: "en-2024_01_11-release_notes-en",
    language: "en",
    html: `
<h2>Smooth invites to MyBusiness</h2>
<p>We've transformed the way you invite new users to MyBusiness. Our latest update isn't just an improvement; it's a complete redefinition of simplicity and efficiency by enabling BankID to the invite flow. Now, every invitation to MyBusiness is a breeze.</p>
<ul>
  <li>Introducing BankID to the invitation flow. This ensures a faster and more secure experience.</li>
  <li>Redesigned invitation, offering a refreshed look and feel that enhances your experience at every step.</li>
</ul>
`,
    data: { date: "11 Jan 2024", type: "improvement" },
  },
  {
    id: "sv-2024_01_11-release_notes-sv",
    language: "sv",
    html: `
<h2>Smidigare inbjudan till MyBusiness</h2>
<p>Vi har omdefinierat processen för hur nya användare inbjuds till MyBusiness. Vår senaste uppdatering är inte bara en förbättring, det är en helt ny definition av enkelhet och effektivitet genom att möjliggöra BankID i inbjudningsflödet. För att säkerställa att varje inbjudan till MyBusiness är så smidig som möjligt.</p>
<ul>
  <li>Integration av BankID i inbjudningsprocessen garanterar en snabbare och säkrare upplevelse.</li>
  <li>Uppfräschat utseende och känsla som förbättrar din upplevelse vid varje steg.</li>
</ul>
`,
    data: { date: "11 jan. 2024", type: "improvement" },
  },
  {
    id: "en-2023_12_05-release_notes",
    language: "en",
    html: `
<h2>Ticket to conversation – support case enhancement</h2>
<p>This update is all about enhancing your experience and giving you greater control over your support journey.</p>
<ul>
  <li>Support case details: See all interactions for your case as a conversational chat overview!</li>
  <li>Custom alerts: Easily choose how you want to receive your notifications on your case.</li>
  <li>Got more to say? Reopen any “Suggested solution”- ticket just by writing a message back to us.</li>
  <li>SLA Insights: See more insights and information linked to your incidents.</li>
  <li>Back in a click: Lost in tickets? Click back to the list in a flash.</li>
</ul>
`,
    data: { date: "5 Dec 2023", type: "improvement" },
  },
  {
    id: "sv-2023_12_05-release_notes",
    language: "sv",
    html: `
<h2>Från information till konversation – bättre upplevelse och kontroll av supportärenden</h2>
<p>Med den här uppdateringen lyfter vi upplevelsen och ger dig bättre kontroll över dina supportärenden.</p>
<ul>
  <li>Dina supportärenden: Se alla interaktioner för ditt ärende som en samtalschatt!</li>
  <li>Anpassa aviseringarna: Nu är det lekande lätt att välja hur du vill bli aviserad.</li>
  <li>Öppna ärendet på nytt: Har du mer att säga? Öppna ett ärende markerat med "Föreslagen lösning” bara genom att skicka oss ett meddelande tillbaka.</li>
  <li>SLA-insikter: Få fler insikter och mer information om dina incidenter.</li>
  <li>Hitta alltid tillbaka: Gå inte vilse. Du kommer tillbaka till översiktsidan på ett klick.</li>
</ul>
`,
    data: { date: "5 dec. 2023", type: "improvement" },
  },
  {
    id: "en-2023_11_27-release_notes",
    language: "en",
    html: `
<h2>Contact information in one place 🗃️</h2>
<p>In the past, your login information also served as your contact details. Now, your contact information (email and phone number) will be stored in a new, central system, completely independent of your login credentials. This means:</p>
<ul>
  <li>Your contact details will always be up-to-date, no matter which of our services you use.</li>
  <li>You can add, modify, or remove your contact details whenever you wish. Take a stroll to the My Profile under Settings section – that's your new go-to spot for all things contact-related.</li>
</ul>
`,
    data: { date: "27 Nov 2023", type: "improvement" },
  },
  {
    id: "sv-2023_11_27-release_notes",
    language: "sv",
    html: `
<h2>Kontaktinformation på ett ställe 🗃️</h2>
<p>Tidigare fungerade din inloggnings-information också som dina kontaktuppgifter. Nu kommer dina kontaktuppgifter (mejladress och telefonnummer) att lagras i ett nytt centralt system, helt oberoende av dina inloggningsuppgifter. Detta betyder:</p>
<ul>
  <li>Dina kontaktuppgifter kommer alltid att vara uppdaterade, oavsett vilken av våra tjänster du använder.</li>
  <li>Du kan lägga till, ändra eller ta bort dina kontaktuppgifter när du vill. Ta bara en sväng till Min profil under Inställningar – din nya plats för allt kontaktrelaterat.</li>
</ul>
`,
    data: { date: "27 nov. 2023", type: "improvement" },
  },
  {
    id: "en-2023_11_14-release_notes",
    language: "en",
    html: `
<h2>News and updates at a glance 👀</h2>
<ul>
  <li>With our new "What's New in MyBusiness" module, you'll be instantly informed. Here we present the latest changes, features, and fine-tunings in MyBusiness. Keep your eyes open.</li>
</ul>
`,
    data: { date: "14 Nov 2023", type: "release" },
  },
  {
    id: "sv-2023_11_14-release_notes",
    language: "sv",
    html: `
<h2>Nyheter och uppdateringar på ett ögonblick 👀</h2>
<ul>
  <li>Med vår nya modul "Nytt i MyBusiness" blir du upplyst snabbare än du hinner blinka. Här presenterar vi de senaste förändringarna, funktionerna och finjusteringarna i MyBusiness. Håll ögonen öppna.</li>
</ul>
`,
    data: { date: "14 nov. 2023", type: "release" },
  },
  {
    id: "en-2023_11_01-release_notes",
    language: "en",
    html: `
<h2>What's new in MyBusiness 🚨</h2>
<ul>
  <li>What's new in MyBusiness! That's what's new in MyBusiness. Here we'll regularly highlight new releases, updates, and improvements in MyBusiness. It's accessed through a link in the footer, and we plan to introduce a new module for the start page in the future.</li>
</ul>
`,
    data: { date: "1 Nov 2023", type: "release" },
  },
  {
    id: "sv-2023_11_01-release_notes",
    language: "sv",
    html: `
<h2>Nytt i MyBusiness 🚨</h2>
<ul>
  <li>Nytt i MyBusiness! Det är senaste nytt i MyBusiness. Här samlar vi notiser om ny funktionalitet, uppdateringar och förbättringar i MyBusiness. Just nu hittar du hit enklast via en länk i footermenyn, men vi planerar också att introducera en ny modul för startsidan i framtiden.</li>
</ul>
`,
    data: { date: "1 nov. 2023", type: "release" },
  },
  {
    id: "en-2023_10_13-release_notes",
    language: "en",
    html: `
<h2>Enhanced User overview 🔍</h2>
<p>We have launched a new overview with new requested functionality for managing users!</p>
<ul>
  <li>Combine searches on multiple columns for greater accuracy.</li>
  <li>More choices of columns, such as <strong>Product categories</strong>, <strong>Latest login</strong>, <strong>Invited by</strong>, and <strong>Invitation date</strong>.</li>
  <li>Option to expand the view to fill the entire screen, as well as export the content to Excel or CSV.</li>
  <li>Performance boost: The page now loads 20 times faster than before!</li>
</ul>
`,
    data: { date: "13 Oct 2023", type: "improvement" },
  },
  {
    id: "sv-2023_10_13-release_notes",
    language: "sv",
    html: `
<h2>Förbättrad användaröversikt 🔍</h2>
<p>Vi har lanserat en ny översikt med ny efterfrågad funktionalitet för hantering av användare!</p>
<ul>
  <li>Kombinera sökningar på flera kolumner för större träffsäkerhet.</li>
  <li>Nya kolumnval, såsom <strong>Produktkategorier</strong>, <strong>Senast inloggad</strong>, <strong>Inbjuden av</strong> och <strong>Inbjuden datum</strong>.</li>
  <li>Möjlighet att expandera vyn för att fylla hela skärmen, och att exportera innehållet till Excel eller CSV.</li>
  <li>Sidan laddar 20 gånger snabbare än tidigare!</li>
</ul>
`,
    data: { date: "13 okt. 2023", type: "improvement" },
  },
  {
    id: "en-2023_09_06-release_notes",
    language: "en",
    html: `
<h2>BankID enhancement ⚡️</h2>
<p>In June, we ramped up the security with our new login platform for MyBusiness and introduced BankID. Some of you gave us feedback – both applause and constructive criticism. So, in early September, we rolled out an improved version.</p>
<ul>
  <li><strong>Seamless login:</strong> Trying to log in with BankID without having it activated? No worries – we guide you. Either upgrade to BankID or create a new MyBusiness account.</li>
  <li><strong>Clear instructions:</strong> Less hassle, more “here´s how”. Follow our simple steps to activate and use BankID.</li>
  <li><strong>Refined design:</strong> Sure, it’s not all about the looks ... but it helps! We’ve given it a little makeover to make your experience even smoother.</li>
</ul>
`,
    data: { date: "6 Sept 2023", type: "improvement" },
  },
  {
    id: "sv-2023_09_06-release_notes",
    language: "sv",
    html: `
<h2>Ett steg framåt med BankID ⚡️</h2>
<p>I juni skruvade vi upp säkerheten ett snäpp med vår nya inloggningsplattform för MyBusiness och introducerade BankID. Några av er gav oss feedback – både applåder och konstruktiv kritik. Så i början av september rullade vi ut en förbättrad version.</p>
<ul>
  <li><strong>Sömlös inloggning:</strong> Försöker du logga in med BankID utan att ha det aktiverat? Inga problem, vi guidar dig rätt! Antingen uppgraderar du till BankID eller så skapar du ett nytt MyBusiness-konto.</li>
  <li><strong>Tydliga instruktioner:</strong> Mindre krångel, mer “så här gör du”. Följ våra enkla steg för att aktivera och använda BankID.</li>
  <li><strong>Förfinad design:</strong> Visst, det handlar inte bara om utseendet... men det hjälper! Vi har gjort en liten makeover för att göra din upplevelse ännu smidigare.</li>
</ul>
`,
    data: { date: "6 sep. 2023", type: "improvement" },
  },
  {
    id: "en-2023_06_27-release_notes",
    language: "en",
    html: `
<h2>Simplified login and registration with BankID 🔏</h2>
<h3>Safe and fast login</h3>
<ul>
  <li>We’ve taken our login and registration procedures to the next level, all for your convenience. We’re now introducing a smooth login and registration with BankID, allowing you to bypass those tricky passwords and get started even quicker. 🔓</li>
</ul>
<h3>How to get started with BankID?</h3>
<ul>
  <li>If you already have a MyBusiness account, you can easily switch to BankID by logging in and changing your login method under profile settings. A little heads-up: once you’ve switched to BankID, say goodbye to your old password. But don’t worry, if you’re fond of the old ways, that’s perfectly fine too!</li>
  <li>This fall, we’re also introducing a reimagined invitation process, making it even easier to invite and welcome your colleagues to MyBusiness.</li>
</ul>
`,
    data: { date: "27 Jun 2023", type: "improvement" },
  },
  {
    id: "sv-2023_06_27-release_notes",
    language: "sv",
    html: `
<h2>Förenklad inloggning och registrering med BankID 🔏</h2>
<h3>Säker och snabb inloggning</h3>
<ul>
  <li>Vi har tagit vårt inloggnings- och registreringsförfarande till nästa nivå, för din bekvämlighet. Nu introducerar vi en smidigare inloggning och registrering med BankID, så att du kan skippa krångliga lösenord och komma igång snabbare. 🔓</li>
</ul>
<h3>Hur kommer du igång med BankID?</h3>
<ul>
  <li>Om du redan har ett MyBusiness-konto, kan du enkelt byta till BankID genom att logga in och ändra din inloggningsmetod under profilsinställningarna. Liten heads-up: När du väl har bytt till BankID, säg hejdå till ditt gamla lösenord. 👋 Men oroa dig inte, vill du hålla fast vid det gamla, går det bra det med!</li>
  <li>Under hösten kommer vi även att introducera en ny inbjudningsprocess, som gör det enklare att bjuda in och välkomna dina kollegor till MyBusiness.</li>
</ul>
`,
    data: { date: "27 juni 2023", type: "improvement" },
  },
  {
    id: "en-2023_06_25-release_notes",
    language: "en",
    html: `
<h2>New landing page for MyBusiness</h2>
<ul>
  <li>You probably know the importance of a great entrance. And that’s why we have refurnished the MyBusiness entrance! 🏰 The revamped entrance to MyBusiness, the landing page, offers a modern design as well as guidance about services and functionalities in MyBusiness. Welcome in!</li>
</ul>
`,
    data: { date: "25 Jun 2023", type: "improvement" },
  },
  {
    id: "sv-2023_06_25-release-notes",
    language: "sv",
    html: `
<h2>Ny MyBusiness-landningssida</h2>
<ul>
  <li>Du känner säkert till vikten av en bra entré. Och det är precis därför vi renoverat vår MyBusiness-entré! 🏰 Den nya landningssidan erbjuder både modern design och en rad smakprov på tjänster och funktionalitet i MyBusiness. Välkommen in!</li>
</ul>
`,
    data: { date: "25 juni 2023", type: "improvement" },
  },
  {
    id: "en-2023_06_09-release_notes",
    language: "en",
    html: `
<h2>New support case flow 💼</h2>
<ul>
  <li>Not really feeling the groove when submitting support tickets? We’ve got some news for you: Support requests have been redesigned from the ground up for an easier and better experience! 🎟️ The new flow guides you in an intuitive way, so that the support request contains the right amount of information for us to provide you with the best help.</li>
</ul>
`,
    data: { date: "9 Jun 2023", type: "improvement" },
  },
  {
    id: "sv-2023_06_09-release_notes",
    language: "sv",
    html: `
<h2>Nytt supportflöde 🚰</h2>
<ul>
  <li>Känner du ibland att du inte riktigt får till ett bra flow när du skickar in supportärenden? Då har vi goda nyheter till dig: Vi har gjort om hela flödet för supportärende från grunden! 🎟️ Det nya flödet är mer intuitivt och guidar dig så att vi får rätt mängd information till oss, så att vi kan hjälpa dig på bästa och snabbast möjliga sätt.</li>
</ul>
`,
    data: { date: "9 juni 2023", type: "improvement" },
  },
] as ReleaseNote[];
